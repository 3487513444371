.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #efe0ff;
  font-family: "Poetsen One", sans-serif;
  background-image: url('../images/wavy background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box; /* Ensure no extra padding increases dimensions */
  overflow-y: auto; /* Enable scrolling if content exceeds height */
  padding: 12px; /* Add some padding to prevent elements from touching edges */
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
  animation: fadeInFromTop 1s ease-in-out; /* Apply fade-in from top */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 65px;
  height: 65px;
}

.welcome-text {
  font-size: 1.5rem;
  color: #353935;
  margin: 10px 0;
}

.sub-text {
  font-size: 1rem;
  color: #34495e;
}

.auth-card {
  background-color: #FAF9F6;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  animation: fadeInFromBottom 1s ease-in-out; /* Apply fade-in from bottom */
}
.policy-text {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #666;
  animation: fadeInFromBottom 1s ease-in-out; 
}

.policy-link {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  font-size: inherit;
}

.policy-link:hover {
  color: #0056b3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-card {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}


@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End at the original position */
  }
}

@keyframes fadeInFromBottom {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End at the original position */
  }
}

/* Media Queries for Smaller Screen Heights */
@media screen and (max-height: 700px) {
  .logo {
    width: 60px;
    height: 60px;
  }

  .welcome-text {
    font-size: 1.2rem;
  }

  .sub-text {
    font-size: 0.9rem;
  }

  .auth-card {
    padding: 15px;
  }

  .logo-container {
    margin-bottom: 10px;
  }
}
