 /* Container */
.loading-container {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #efe0ff;
    font-family: "Poetsen One", sans-serif;
    background-image: url('../images/wavy background.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box; /* Ensure no extra padding increases dimensions */
  }
  
  /* Progress Circle */
  .progress-circle {
    position: relative;
    width: 200px;
    height: 200px;
    background-color: #ffffff;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3), inset 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
  .progress-fill {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fb639c; /* Moon-like golden color */
    transition: height 0.1s ease;
    z-index: 1;
  }
  
  .moon-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 180px;
    height: 180px;
    z-index: 2;
    pointer-events: none; /* Prevent interaction */
  }
  
  /* Loading Text */
  .loading-text {
    margin-top: 20px;
    font-size: 32px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  