@import url('https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap');
/* Reset margins and padding */
body, html, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
  
body {
    display: flex;
    flex-direction: column;
    font-family: 'Poetsen One', sans-serif;
    background-color: #2C3E50;
    /* background-image: url("./images/background.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center; */
  }

  