/* Dream Form Styles */
.dream-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px;
  box-sizing: border-box;
  margin-top: -8px;
}

.form-card {
  width: 100%;
  max-width: 500px;
  padding: 16px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 12px;
  opacity: 0; /* Start invisible */
  transform: translateX(50px); /* Start slightly offscreen */
  animation: slideIn 0.8s ease-out forwards; /* Animation */
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-100px); /* Start offscreen */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* End in place */
  }
}

.form-label {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.dream-form h1 {
  color: #333;
  opacity: 0; /* Start invisible */
  transform: translateX(50px); /* Start slightly offscreen */
  animation: slideIn 0.8s ease-out forwards; /* Animation */
  text-align: center;
  margin: 0;
  margin-bottom: 8px;
}

/* Dream Input Fields */
.dream-input,
.dream-textarea {
  width: 100%;
  padding: 12px;
  color: #333;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  box-sizing: border-box;
  font-size: 16px;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.dream-input:hover,
.dream-textarea:hover {
  border-color: #fb639c;
  background-color: #ffffff;
}

.dream-input:focus,
.dream-textarea:focus {
  border-color: #fb639c;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(251, 99, 156, 0.2);
}

.dream-input::placeholder,
.dream-textarea::placeholder {
  color: #aaa;
  font-style: italic;
}

/* Dream Textarea Specific Styles */
.dream-textarea {
  height: 80px;
  resize: none; /* Disable resizing */
  font-family: 'Arial', sans-serif; /* Ensure consistent font */
}

.character-count {
  font-size: 12px;
  color: #666;
  text-align: right;
  margin-top: -10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-label-group {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.form-label-group i {
  font-size: 18px;
  color: #666;
}

.form-label-group .form-label {
  font-size: 18px;
  color: #333;
}

.form-mood-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)); /* Adjust to fit 3 items per row */
  gap: 15px;
  justify-content: space-between;
}

.mood-radio {
  font-size: 36px;
  color: #ccc;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: color 0.3s ease;
}

.mood-radio.selected {
  color: #fb639c;
}

.mood-label {
  font-size: 12px;
  color: #333;
  margin-top: 5px;
}

/* Gender radio group */
.form-radio-group {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Hide default radio button */
.form-radio-group input[type="radio"] {
  display: none;
}

/* Custom styled radio button */
.gender-radio {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  background-color: white;
}

.gender-radio.selected {
  border-color: #fb639c;
  background-color: #fb639c;
}

.gender-radio i {
  font-size: 16px;
  color: transparent;
  transition: color 0.3s ease;
}

.gender-radio.selected i {
  color: white;
}

.gender-label {
  margin-top: 8px;
  font-size: 14px;
  text-align: center;
  color: #333;
}

.form-actions {
  display: flex;
  justify-content: center;
}

.submit-button {
  background-color: #fb639c;
  color: white;
  padding: 10px 20px;
  width: 200px;
  height: 40px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  margin-top: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.submit-button:hover {
  background-color: #b93d6a;
}

.submit-button.disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

/* Snackbar */
.error-snackbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #d32f2f;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  box-sizing: border-box;
  width: 400px; /* Default width for desktop */
}

.close-snackbar {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}
.spinner {
  width: 24px;
  height: 24px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Age Slider Styles */
.age-slider {
  width: 100%;
  appearance: none;
  height: 10px;
  border-radius: 5px;
  background: linear-gradient(to right,#fbb4d1,#fb639c);
  outline: none;
  transition: 0.3s ease;
  cursor: pointer;
}

.age-slider::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fb639c;
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease;
}

.age-slider::-webkit-slider-thumb:hover {
  background: #b93d6a;
}

.age-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fb639c;
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease;
}

.age-slider::-moz-range-thumb:hover {
  background: #b93d6a;
}

.slider-value {
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .mood-radio {
    font-size: 30px;
  }
  .error-snackbar {
    width: calc(100% - 24px); /* Full width minus padding */
    left: 12px; /* Add padding to the left */
    right: 12px; /* Add padding to the right */
    transform: none; /* Remove centering transform for mobile */
  }
}
