/* Saved Dream Detail Styles */
.saved-dream-detail {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  opacity: 0;
  transform: translateX(-50px);
  transition: all 0.8s ease-out;
}
.saved-dream-detail h1{
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin: 0;
  margin-bottom: 8px;
}

.saved-dream-detail.visible {
  opacity: 1;
  transform: translateX(0);
}

.saved-dream-card-detail {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
}

.dream-date{
  margin: 0;
  margin-top: 4px;
}

/* Action Buttons */
.saved-dream-actions {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1; /* Ensure buttons stay above content */
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 28px;
  color: #333;
  transition: color 0.3s ease;
}

.icon-button:hover {
  color: #fb639c;
}

/* Content */
.saved-dream-content {
  flex: 1;
  overflow-y: auto; /* Make content scrollable */
  max-height: 525px; /* Adjust height for consistent scrolling area */
  scrollbar-width: thin; /* For modern browsers */
  scrollbar-color: #fb639c #f0f0f0; /* Thumb and track colors */
}

.dream-title {
  font-size: 32px;
  color: #333;
  margin-bottom: 16px;
}

.dream-subtitle {
  font-size: 24px;
  color: #333;
  margin-bottom: 8px;
  margin-top: 8px;
}

/* Scrollable Dream Text */
.dream-text {
  font-size: 18px;
  color: #333;
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
  line-height: 1.35; /* Increase line spacing for better readability */
  white-space: pre-wrap; /* Preserve line breaks and spacing */
  border-radius: 4px;
}

/* Webkit-based browsers */
.saved-dream-content::-webkit-scrollbar {
  width: 10px;
}

.saved-dream-content::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.saved-dream-content::-webkit-scrollbar-thumb {
  background-color: #fb639c;
  border-radius: 4px;
}

.saved-dream-content::-webkit-scrollbar-thumb:hover {
  background-color: #e0558c;
}

/* Always show scrollbar */
@media (hover: none) and (pointer: coarse) {
  .saved-dream-content {
    scrollbar-gutter: stable;
  }
}
