.about-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #34495e;
  color: #ecf0f1;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-image: url('../images/wavy background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap; /* Allow wrapping if absolutely necessary */
}

.about-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-image {
  max-width: 100%; /* Ensure the image doesn't overflow */
  height: auto;
}

.about-text {
  flex: 1;
  text-align: left;
  max-width: 600px;
}

.about-text h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-family: 'Poetsen One', sans-serif;
}

.about-text .highlight {
  color: #fb639c;
}

.about-text p {
  font-size: 1.2rem;
  line-height: 1.6;
}

.about-icons {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Reduce gaps between icons */
  width: 100%; /* Ensure it spans the full width */
  flex-wrap: nowrap; /* Prevent wrapping */
}

.about-icon {
  flex: 1;
  text-align: center;
  padding: 15px;
  background-color: #fb639c;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 33%; /* Divide icons evenly */
  box-sizing: border-box; /* Include padding in width calculations */
}

.about-icon i {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 10px;
}

.about-icon h3 {
  font-size: 1.2rem;
  margin: 10px 0;
  color: white;
}

.about-icon p {
  font-size: 0.9rem;
  line-height: 1.4;
  color: whitesmoke;
}

.about-icon:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}


/* Centered CTA Button */
.cta-button-container {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 30px;
}

.cta-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 1.5rem;
  color: #ffffff;
  background-color: #fb639c;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  text-align: center;
  font-family: 'Poetsen One', sans-serif;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.cta-button:hover {
  background-color: #c43d6b; /* Darker pink for hover effect */
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.cta-button:active {
  transform: scale(0.98);
}


/* Responsive Design */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    text-align: center;
  }

  .about-image-container {
    margin-bottom: 20px;
  }

  .about-text h2 {
    font-size: 2rem;
    text-align: center;
  }

  .about-text p {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 24px;
  }

  .about-icons {
    gap: 5px; /* Reduce gap for smaller screens */
    justify-content: space-around; /* Spread icons evenly */
  }

  .about-icon {
    max-width: calc(33.33% - 10px); /* Ensure icons are evenly spaced */
    padding: 10px; /* Reduce padding */
  }
}

@media (max-width: 480px) {
  .about-text h2 {
    font-size: 1.8rem;
  }

  .about-text p {
    font-size: 0.9rem;
  }

  .about-icons {
    gap: 5px;
  }

  .about-icon {
    max-width: calc(33.33% - 10px); /* Ensure icons stay in a row */
    padding: 8px; /* Reduce padding */
  }

  .about-icon h3 {
    font-size: 0.9rem; /* Adjust heading size */
  }

  .about-icon p {
    font-size: 0.8rem; /* Adjust text size */
  }
}

@media (max-width: 345px) {
  .about-section {
    padding: 10px; /* Reduce padding for very small screens */
  }

  .about-text h2 {
    font-size: 1.5rem; /* Adjust font size */
  }

  .about-text p {
    font-size: 0.8rem; /* Adjust paragraph text */
  }

  .about-icons {
    gap: 5px; /* Reduce gap further */
  }

  .about-icon {
    padding: 5px; /* Minimal padding */
    max-width: calc(33.33% - 5px); /* Keep icons in one row */
  }

  .about-icon h3 {
    font-size: 0.9rem;
  }

  .about-icon p {
    font-size: 0.7rem;
  }
}
