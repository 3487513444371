.faq-section {
    padding: 60px 20px;
    background-color: #efe0ff;
    margin-top: -1px;
  }
  
  .faq-section h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2rem;
    color: #333;
  }
  
  .faq-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-item {
    border-radius: 6px;
    margin-bottom: 15px;
    overflow: hidden;
    transition: all 0.3s ease;
    background-color: #fff;
  }
  
  .faq-item.active {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  }
  
  .faq-question {
    width: 100%;
    background: none;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
    color: #333;
    transition: background-color 0.3s ease;
  }
  
  .faq-question:hover {
    background-color: #f1f1f1;
  }
  
  .faq-icon {
    font-size: 1.2rem;
    color: #666;
    margin-left: 10px;
    transition: transform 0.3s ease;
  }
  
  /* We'll animate the answer to slide open/closed */
  .faq-answer {
    max-height: 0;
    opacity: 0;
    padding: 0 20px;
    transition: max-height 0.3s ease, opacity 0.3s ease;
  }
  
  .faq-answer p {
    margin: 0;
    padding: 15px 0;
    color: #555;
  }
  
  .faq-answer.expanded {
    max-height: 150px; /* Increase if your content is longer */
    opacity: 1;
    padding-bottom: 15px;
  }
  