/* MyDreams Styles */
.dreams-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.dreams-title {
  color: #333;
  margin: 0 8px;
  font-size: 36px;
  opacity: 0; /* Start invisible */
  transform: translateX(-50px); /* Start slightly offscreen to the left */
  animation: slideFadeIn 0.8s ease-out forwards; /* Apply animation */
}

.dreams-container {
  width: 100%;
  max-height: 530px; /* Set the height to fit the number of cards properly */
  overflow-y: auto;
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  opacity: 0; /* Start invisible */
  transform: translateX(-50px); /* Start slightly offscreen to the left */
  animation: slideFadeIn 0.8s ease-out forwards; /* Apply animation */
}

.dreams-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  justify-content: center;
  width: 100%;
}

.dreams-grid-item {
  width: 100%;
}

.no-dreams {
  color: #333;
  text-align: center;
  margin-top: 16px;
  font-size: 18px;
}

/* Animation */
@keyframes slideFadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100px); /* Start offscreen to the left */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* End in place */
  }
}
