.modal-content {
  position: relative;
  width: 100%;
  max-width: 800px;
  border-radius: 5px;
  color: white;
  text-align: center;
  box-sizing: border-box; /* Prevent overflow due to padding */
}
.modal-title{
  animation: slideInFade 0.5s ease-out forwards;
}
.modal-description{
  animation: slideInFade 0.5s ease-out forwards;
}

.modal-content h2 {
  color: #333;
  margin: 0;
}

.modal-content p {
  font-size: 18px;
  margin-bottom: 18px;
  margin-top: 18px;
}

.package-container {
  display: flex;
  gap: 16px; /* Reduced gap */
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 10px; /* Prevent items from touching edges */
  box-sizing: border-box; /* Ensures padding fits in width */
}

.package-card {
  flex: 1;
  min-width: 250px;
  max-width: 300px;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  flex-shrink: 0; /* Prevent shrinking */
  opacity: 0; /* Start hidden for animation */
  transform: translateX(-50px); /* Start off-screen to the left */
  animation: slideInFade 0.5s ease-out forwards;
}

/* Add delay for each card */
.package-card:nth-child(1) {
  animation-delay: 0.2s;
}

.package-card:nth-child(2) {
  animation-delay: 0.4s;
}

.package-card:nth-child(3) {
  animation-delay: 0.6s;
}

.package-card h3 {
  margin: 0;
  font-size: 1.5rem;
}

.package-card p {
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.package-card button {
  width: 100%;
  padding: 10px 0;
  background-color: #fb639c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.package-card button:hover {
  background-color: #b83e6a;
}

/* Animation Keyframes */
@keyframes slideInFade {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
