.reviews-section {
  min-height: 100vh;
  padding: 50px 20px;
  text-align: center;
  background-color: #efe0ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.reviews-header h2 {
  font-size: 3rem;
  margin-bottom: 10px;
  font-family: "Poetsen One", sans-serif;
}

.reviews-header p {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.review-cards-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

.review-card {
  background: linear-gradient(
    135deg,
    #fb639c 0%,
    #d6bffb 50%,
    #97addd 100%
  ); /* Gradient background */
  color: #ecf0f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  flex: 1 1 calc(100% / 3 - 20px); /* Adjusts width dynamically for 3 columns */
  max-width: 300px;
  min-width: 200px; /* Ensures cards don’t shrink too small */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.review-card:hover {
  transform: scale(1.05); /* Hover effect overrides position */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.review-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 15px;
}

.review-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333333;
}

.review-role {
  font-size: 1rem;
  color: white;
}

.review-comment {
  font-size: 1rem;
  line-height: 1.6;
  margin: 20px 0;
}

.review-rating {
  font-size: 1.5rem;
  color: gold;
  text-align: right;
}

.red-highlight {
  color: #ff033e;
  font-size: 28px;
}

.cta-button {
  display: inline-block;
  margin-top: 64px; /* Adds space between the services and the button */
  padding: 15px 30px;
  font-size: 1.5rem;
  color: #ffffff;
  background-color: #fb639c; /* Matches the color scheme */
  border: none;
  border-radius: 8px;
  text-decoration: none; /* Remove underline for links */
  text-align: center;
  font-family: 'Poetsen One', sans-serif;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  cursor: pointer;
  margin-bottom: 48px;
}

.cta-button:hover {
  background-color: #c43d6b; /* Darker pink for hover effect */
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.cta-button:active {
  transform: scale(0.98); /* Pressed effect */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .review-cards-container {
    flex-direction: column;
    gap: 20px;
    align-items: center;
    flex-wrap: nowrap;
  }

  .review-card {
    width: 100%;
    max-width: 90%;
    margin-bottom: 24px;
  }

  .review-comment {
    font-size: 0.95rem;
  }
  .review-cards-container{
    padding: 14px;
  }
}

@media (max-width: 480px) {
  .reviews-header h2 {
    font-size: 2.3rem;
  }

  .reviews-header p {
    font-size: 1.2rem;
  }

  .review-card {
    width: 90%; /* Keeps the cards within the screen */
    max-width: 320px; /* Ensures cards fit within smaller screens */
    min-width: 200px; /* Prevent cards from becoming too small */
  }

  .review-comment {
    font-size: 0.9rem;
  }

  .review-name {
    font-size: 1.3rem;
  }
}
