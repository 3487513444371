.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  height: 90vh;
  background-color: #efe0ff;
  background-image: url('../images/wavy background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.hero-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  width: 100%;
  flex-wrap: wrap; /* Ensures responsiveness */
  margin-bottom: 124px;
  margin-top: 32px;
}

.hero-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #fea5ab, #fb639c);
  border-radius: 20px;
  padding: 24px;
  max-width: 400px;
  height: 400px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  /* Animation */
  opacity: 0;
  transform: translateX(-50px);
  animation: slideInLeft 1s forwards;
}

.hero-text h2{
  font-size: 18px;
  color: #333;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 12px;
}

.hero-image {
  max-width: 100%;
  max-height: 100%;
}

.hero-text {
  flex: 1;
  max-width: 600px;
  padding: 8px;
  /* Animation */
  opacity: 0;
  transform: translateX(50px);
  animation: slideInRight 1s forwards;
  animation-delay: 0.5s;
}

.hero-text h1 {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.3;
  color: #000000;
}

.hero-text .highlight {
  color: #fb639c;
}

.hero-text p {
  margin: 0;
  font-size: 0.9rem;
  color: #4a4a4a;
  line-height: 1.4;
}

.hero-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #fb639c;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Poetsen One', sans-serif;
}

.hero-button:hover {
  background-color: #ee8096;
}

/* Scroll Indicator */
.scroll-indicator {
  position: absolute;
  bottom: 35px; /* Place it at the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust centering */
  font-size: 3rem;
  color: #fb639c;
  animation: fadeInSlideBounce 3s ease;
  cursor: pointer; /* Indicates interactivity */
}

/* Animation for bounce effect */
@keyframes bounce {
  0%, 100% {
    transform: translate(-50%, 0); /* Default position */
  }
  50% {
    transform: translate(-50%, 10px); /* Move down slightly */
  }
}

@keyframes fadeInSlideBounce {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-20px); /* Slightly above initial position */
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0); /* Final position */
  }
}

/* Keyframes for animations */
@keyframes slideInLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
/* Keyframes for mobile animations */
@keyframes slideInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-content {
    flex-direction: column; /* Stack items on smaller screens */
    gap: 40px;
  }

  .hero-text {
    text-align: center; /* Center-align all text content */
    /* Adjust animation */
    opacity: 0;
    transform: translateY(50px);
    animation: slideInUp 1s forwards;
    animation-delay: 0.5s;
  }

  .hero-text h1 {
    font-size: 2rem;
  }

  .hero-text p {
    text-align: center;
  }

  .hero-button {
    margin: 20px auto 0; /* Centers the button horizontally */
    display: block; /* Ensures it takes up block space */
  }

  .hero-image-container {
    width: 100%;
    max-width: 300px; /* Scales down image container */
    height: 300px;
    /* Adjust animation */
    opacity: 0;
    transform: translateY(-50px);
    animation: slideInDown 1s forwards;
  }
}

@media (max-width: 500px) {
  .hero-image-container {
    max-width: 230px; /* Scales down further for very small screens */
    height: 230px; /* Adjust height proportionally */
    
  }
  .hero-content {
    flex-direction: column; /* Stack items on smaller screens */
    gap: 12px;
  }
  .hero-section{
    padding: 8px;
  }

  .hero-text {
    text-align: center; /* Center-align all text content */
    /* Adjust animation */
    opacity: 0;
    transform: translateY(50px);
    animation: slideInUp 1s forwards;
    animation-delay: 0.5s;
  }

  .hero-text h1 {
    font-size: 2rem;
    margin: 0;
  }

  .hero-text p {
    text-align: center;
  }

  .hero-button {
    margin: 20px auto 0; /* Centers the button horizontally */
    display: block; /* Ensures it takes up block space */
  }
}

@media (max-width: 360px) {
  .hero-image-container {
    max-width: 200px; /* Even smaller for the smallest screens */
    height: 200px; /* Adjust height proportionally */
  }
}
