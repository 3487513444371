.footer {
  background-color: #8b9cf6;
  color: #ecf0f1;
  padding: 40px 20px;
  text-align: center;
  font-family: "Poetsen One", sans-serif;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-section h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: white;
}

.social-icons a {
  color: white;
  font-size: 1.5rem;
  margin: 0 10px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icons a:hover {
  transform: scale(1.2);
  color: #fb639c;
}

.nav-links {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adds spacing between links */
}

.nav-links a {
  color: white;
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #fb639c;
}

.policy-links {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adds spacing between links */
}

.policy-links a {
  color: white;
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.policy-links a:hover {
  color: #fb639c;
}

.contact-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-info span {
  font-size: 1rem;
  transition: color 0.3s ease;
}

.contact-info span:hover{
  color: #fb639c;
}

.contact-info i {
  font-size: 1.2rem;
  color: white;
}

.footer-note {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #34495e;
  margin-top: 48px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-card {
  background: #fff;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px; /* Default max width for larger screens */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  text-align: center;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.modal-card h2 {
  font-size: 1.5rem;
  margin: 0;
}

.modal-body {
  max-height: 500px; /* Set max height */
  overflow-y: auto; /* Enable scrolling if content overflows */
  margin: 15px 0;
  font-size: 1rem;
  line-height: 1.5;
  white-space: pre-wrap; /* Preserve line breaks from the file */
  text-align: left;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.modal-close:hover {
  color: #000;
}

.policy-button {
  border: none;
  background-color: transparent;
  color: white;
  font-family: "Poetsen One", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s ease;
}
.policy-button:hover {
  color: #fb639c;
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  .footer-section h3 {
    font-size: 1.2rem;
  }

  .nav-links a,
  .policy-links a,
  .contact-info span,
  .policy-button {
    font-size: 0.9rem;
  }
  .modal-card {
    padding: 20px; /* Extra padding for smaller devices */
    margin: 0 20px; /* Add space around the card */
    max-width: 90%; /* Ensure it doesn't stretch too wide */
  }
}

@media (max-width: 480px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .footer-section h3 {
    font-size: 1.5rem;
  }

  .social-icons a {
    font-size: 2rem;
  }

  .nav-links a,
  .policy-links a {
    font-size: 0.9rem;
  }

  .contact-info span {
    font-size: 0.9rem;
  }
  .modal-card {
    padding: 15px; /* Slightly reduce padding for very small screens */
    margin: 0 15px; /* Add even more space on smaller devices */
  }
}
