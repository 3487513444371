/* Saved Dream Card Styles */
.saved-dream-card {
    width: 100%; /* Default full width */
    max-width: 400px; /* Limit width on larger screens */
    background-color: white;
    color: #333;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box; /* Include padding in width */
  }
  /* Hover effect remains unchanged */
  .saved-dream-card:hover {
    background-color: #fb639c;
    color: white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  .dreams-title{
    margin-top: 34px;
    font-size: 2em;
  }
  
  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .fa-arrow-right {
    font-size: 20px;
    color: #333;
  }
  
  /* Parent Container */
  .dreams-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center cards on desktop */
    justify-content: flex-start;
    width: 100%;
    max-height: 650px;
    overflow-y: auto;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    box-sizing: border-box;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .saved-dream-card {
      max-width: 100%; /* Full width on mobile */
    }
  
  }
  