.dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    background-color: #efe0ff;
    font-family: "Poetsen One", sans-serif;
    background-image: url('../images/wavy background.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box; /* Ensure no extra padding increases dimensions */
  }
  
  .dashboard-content {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .thank-you-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .thank-you-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .thank-you-content h2 {
    margin-bottom: 20px;
  }
  
  .thank-you-content button {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .thank-you-content button:hover {
    background-color: #d32f2f;
  }
  