/* App Bar Animation */
.app-bar {
  background: #8b9cf6;
  color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 5px 20px;
  z-index: 3;
  position: sticky;
  top: 0;
  opacity: 0; /* Initially hidden */
  transform: translateY(-50px); /* Start slightly above */
  animation: fadeSlideDown 0.8s ease-out forwards; /* Animation to fade and slide down */
}

/* App Bar Animation Keyframes */
@keyframes fadeSlideDown {
  0% {
    opacity: 0;
    transform: translateY(-50px); /* Start offscreen */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Slide into place */
  }
}
  
  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Burger Icon */
  .burger-icon {
    background: none;
    border: none;
    font-size: 32px;
    color: white;
    cursor: pointer;
  }
  
  .burger-icon:hover {
    color: #fb639c;
  }
  
  /* Logo Container */
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .app-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
  
  /* User Container */
  .user-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .coins-text {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 18px;
  }
  
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #FF7043;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }
  
  /* Side Menu */
  .side-menu {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #c4c5ff;
    color: white;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    transition: left 0.3s ease, opacity 0.3s ease;
    opacity: 0;
  }
  
  .side-menu.open {
    left: 0;
    opacity: 1;
  }
  
  /* Menu Header */
.menu-header {
    display: flex;
    justify-content: flex-end;
  }
  
  .close-icon {
    background: none;
    border: none;
    font-size: 36px;
    color: white;
    cursor: pointer;
    padding: 5px; /* Add some padding for better clickability */
  }
  
  .close-icon:hover {
    color: #fb639c;
  }
  
  /* Menu Links */
.menu-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0; /* No gap to align borders properly */
  }
  
  .menu-links li {
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start; /* Align text and icons to the left */
    padding: 15px 20px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  .menu-links li:first-child{
    border-top: 2px solid white;
  }
  
  .menu-links li:hover {
    background-color: #fb639c;
    color: white;
  }
  
  .menu-links li i {
    margin-right: 10px; /* Add spacing between the icon and text */
  }
  
  .menu-links li:last-child {
    border-bottom: 2px solid white;
  }

  /* Help Section */
.help-section {
  position: absolute;
  bottom: 10px;
  left: 20px;
  text-align: left;
}

.help-title {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin: 0;
}

.help-email {
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.help-email:hover {
  color: #fb639c;
}

  
  
  /* Mobile Responsive Styles */
  @media (max-width: 768px) {
    .side-menu {
        width: 100%; /* Full screen width for mobile */
        left: -100%;
        transition: left 0.3s ease, opacity 0.3s ease;
      }
    
      .side-menu.open {
        left: 0; /* Slide in from the left */
        opacity: 1;
      }
    
      .menu-header {
        justify-content: flex-end;
        padding: 15px; /* Ensure proper spacing for the close button */
      }
  
    .menu-links li {
      font-size: 28px; /* Larger text for mobile readability */
    }
  
    .coins-text {
      font-size: 18px;
    }
  
    .burger-icon {
      font-size: 32px; /* Adjust icon size for mobile */
    }
  
    .app-title {
      font-size: 18px; /* Adjust title size for mobile */
    }
  
    .user-avatar {
      width: 35px;
      height: 35px; /* Slightly smaller avatar */
    }
  }
  