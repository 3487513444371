.navbar {
  position: fixed; /* Ensures the navbar stays at the top */
  top: 0;
  left: 0;
  width: 100%; /* Stretches across the page */
  display: flex;
  justify-content: space-between; /* Align logo and links properly */
  align-items: center;
  padding: 0.5rem 1rem; /* Adjust padding for better fit */
  background: #8b9cf6;
  z-index: 1000;
  height: 60px; /* Set a consistent height */
  box-sizing: border-box; /* Include padding in width calculation */
}

.navbar-logo-img {
  width: 50px;
}
.navbar-logo{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
}
.navbar-logo h1{
  letter-spacing: 0.15em;
  font-size: 18px;
}

.navbar-links a {
  color: white;
  margin-left: 1.5rem;
  text-decoration: none;
  font-size: 1rem;
  position: relative;
  cursor: pointer; /* Adds pointer cursor for links */
}

.navbar-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #ee8096;
  transition: width 0.3s ease;
  position: absolute;
  left: 0;
  bottom: -4px;
}

.navbar-link:hover::after {
  width: 100%;
}

.navbar-button {
  margin-left: 1.5rem;
  padding: 0.5rem 1rem;
  background: #fb639c;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer; /* Adds pointer cursor for the button */
  font-size: 1rem;
  transition: transform 0.2s ease, background-color 0.3s ease;
  text-decoration: none; /* Ensures no underline for the button */
}


/* Responsive Design */
@media (max-width: 768px) {
  .navbar-links {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%; /* Full-screen overlay */
    background-color: #c4c5ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(100%); /* Start off-screen */
    opacity: 0; /* Invisible by default */
    z-index: 5;
    transition: transform 0.5s ease, opacity 0.5s ease; /* Smooth transition for both transform and opacity */
  }

  .navbar-links.open {
    transform: translateX(0); /* Slide into view */
    opacity: 1; /* Fade in */
  }

  .navbar-links.closing {
    transform: translateX(100%); /* Slide out of view */
    opacity: 0; /* Fade out */
  }

  .burger-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    font-size: 2.5rem;
    color: white;
    z-index: 20;
  }

  .burger-lines {
    width: 25px;
    height: 3px;
    background-color: white;
    position: relative;
  }

  .burger-lines::before,
  .burger-lines::after {
    content: '';
    width: 25px;
    height: 3px;
    background-color: white;
    position: absolute;
    left: 0;
    transition: 0.3s;
  }

  .burger-lines::before {
    top: -8px;
  }

  .burger-lines::after {
    top: 8px;
  }

  .navbar-links a {
    margin: 1rem 0;
    font-size: 2rem;
  }

  .navbar-button {
    margin: 1rem 0;
  }
}

