.services-section {
  background-color: #efe0ff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  text-align: center;
  color: #4a4a4a;
}

h2 {
  font-size: 2.5rem;
  color: #fb639c;
  margin-bottom: 40px;
  font-family: 'Poetsen One', sans-serif;
}

.services-container {
  display: flex;
  justify-content: center; /* Center the cards */
  flex-wrap: wrap; /* Wrap cards to new rows if needed */
  gap: 20px;
  width: 100%;
  max-width: 1200px; /* Restrict the container width */
  padding: 0 10px; /* Add padding to prevent overflow */
  box-sizing: border-box; /* Ensure padding is included in width calculation */
  padding-bottom: 24px;
}

.services-intro{
  margin: 0;
  margin-bottom: 24px;
  padding: 8px;
}

.service {
  text-align: center;
  width: 100%;
  max-width: 300px;
  min-height: 350px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8); /* Fallback background */
  background-image: linear-gradient(
    135deg,
    #c3b4df 25%,
    #e1d3ff 25%,
    #e1d3ff 50%,
    #c3b4df 50%,
    #c3b4df 75%,
    #e1d3ff 75%,
    #e1d3ff 100%
  ); /* Creates the diagonal striped pattern */
  background-size: 100px 100px; /* Adjust the width of the lines */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  opacity: 0;
  transform: translateX(-100px); /* Slide in from the left */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.service.in-view {
  opacity: 1;
  transform: translateX(0); /* Reset position */
}

.service:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(
    135deg,
    #c3b4df 25%,
    #e1d3ff 25%,
    #e1d3ff 50%,
    #c3b4df 50%,
    #c3b4df 75%,
    #e1d3ff 75%,
    #e1d3ff 100%
  ); /* Keeps the pattern on hover */
}


.service-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  height: 150px;
}

.service-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

h3 {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #2c3e50;
  transition: color 0.3s ease;
}

p {
  font-size: 1rem;
  color: #4a4a4a;
  transition: color 0.3s ease;
}

.cta-button {
  display: inline-block;
  margin-top: 30px; /* Adds space between the services and the button */
  padding: 15px 30px;
  font-size: 1.5rem;
  color: #ffffff;
  background-color: #fb639c; /* Matches the color scheme */
  border: none;
  border-radius: 8px;
  text-decoration: none; /* Remove underline for links */
  text-align: center;
  font-family: 'Poetsen One', sans-serif;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  cursor: pointer;
  margin-bottom: 48px;
}

.cta-button:hover {
  background-color: #c43d6b; /* Darker pink for hover effect */
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.cta-button:active {
  transform: scale(0.98); /* Pressed effect */
}

/* Responsive Design */
@media (max-width: 768px) {
  .services-section {
    min-height: 100%; /* Allow the section to grow */
    padding: 0 8px; /* Add padding to prevent overflow */
  }

  h2 {
    font-size: 2.2rem; /* Slightly larger for better visibility */
    margin-bottom: 30px;
  }

  .services-container {
    flex-direction: column; /* Stack the cards vertically */
    align-items: center;
  }

  .service {
    max-width: 90%; /* Ensure consistent width */
    width: 100%;
    padding: 20px; /* Maintain padding */
    min-height: 320px; /* Slightly taller cards */
    margin-bottom: 24px;
  }

  h3 {
    font-size: 1.5rem; /* Larger heading text */
  }

  p {
    font-size: 1.1rem; /* Larger paragraph text */
  }
  .cta-button {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
    padding: 12px 48px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 2rem;
  }

  .service-image-container {
    height: 120px; /* Adjust image container height */
  }

  .service {
    min-height: 300px; /* Adjust card height */
  }

  h3 {
    font-size: 1.4rem; /* Larger heading text */
  }

  p {
    font-size: 1rem; /* Larger paragraph text */
  }
}
